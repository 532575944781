import tw from 'twin.macro';
import styled from "@emotion/styled";

const PostContent = styled.main`
  strong {
    font-weight: 600;
  }

  img {
    max-width: 100%;
    width: 100%;
  }

  padding: 60px 20px;

  p, ul, ol {
    margin-bottom: 20px;
    padding: 0 28px;
    ${props => props.fullwidth ? 'padding: 0;' : ''}
    font-size: 14px;
    line-height: 2;
    letter-spacing: -0.27px;
  }

  ul {
    list-style-type: disc;
    padding-left: 2rem;
  }

  .pull-quote {
    padding: 50px 28px;

    .quote {
      font-weight: 600;
      text-align: center;
      color: var(--color-magenta);
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.17px;
    }
    .quote-attribution {
      text-align: center;
      font-family: attribute-mono, monospace;
      text-transform: uppercase;

      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.14px;
      margin: 10px 0 0;
    }
  }

  .feature-stat {
    div {
      background-color: var(--color-offWhite);
      width: 100%;
      height: auto;
      position: relative;
      display: flex; // make us of Flexbox
      align-items: center; // does vertically center the desired content
      justify-content: center; // horizontally centers single line items
      text-align: center; // o

      &:after {
        content: "";
        display: block;
        padding-bottom: calc(55.73308271%);
      }
      p.stat {
        position: absolute;
        left: 0;
        right: 0;
        font-weight: 600;
        color: var(--color-magenta);
        text-align: center;
        margin: 0;

        font-size: 87.51px;
        line-height: 106px;
        letter-spacing: -6.52px;

        span {
          font-size: 52.5px;
          line-height: 40.6px;
          letter-spacing: -2.25px;
        }
      }
    }
    p.feature-stat-description {
      text-align: center;
      color: var(--color-grey);

      margin-top: 10px;
      font-size: 12px;
      line-height: 19px;
      letter-spacing: -0.12px;
    }
  }

  .feature-image {
    margin: 40px 0;

    figure.img {
      width: 100%;

      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      &:after {
        content: "";
        display: block;
        padding-bottom: 55.7%;
      }
    }

    p.feature-image-attribution {
      text-align: center;
      color: var(--color-grey);

      margin-top: 10px;
      font-size: 12px;
      line-height: 19px;
      letter-spacing: -0.12px;
    }
  }

  .call-to-action {
    ${tw`px-6 my-12 sm:px-12 lg:px-24`}
  }

  .call-to-action__copy {
    ${tw`font-sofia font-black leading-snug text-center text-2xl sm:text-3xl md:text-4xl text-magenta`}
  }

  .call-to-action__button-container {
    ${tw`flex justify-center mt-10`}
  }

  .call-to-action__button {
    ${tw`font-mono inline-block uppercase text-center bg-magenta text-white py-4 px-6 w-80 max-w-full rounded-full`}
  }

  .well {
    ${tw`my-12 px-5 py-8 -mx-2 md:p-12 md:-mx-12 xl:my-24 xl:p-24 xl:-mx-24 bg-offWhite`}

    h2 {
      ${tw`font-sofia font-black leading-snug text-center text-2xl sm:text-3xl md:text-4xl text-magenta m-0`}
    }

    h3 {
      ${tw`font-sofia font-black leading-snug text-center text-xl sm:text-3xl text-black m-0`}
    }

    p {
      ${tw`text-center text-base lg:text-lg`}
    }


    h1:not(:first-child),
    h2:not(:first-child),
    h3:not(:first-child),
    h4:not(:first-child),
    h5:not(:first-child),
    h6:not(:first-child),
    p:not(:first-child) {
      ${tw`mt-6`}
    }
  }

  @media (min-width: 768px) {
    padding: 64px;

    p, ul, ol {
      font-size: 19px;
      letter-spacing: -0.36px;
      margin-bottom: 30px;
      padding: 0 60px;
      ${props => props.fullwidth ? 'padding: 0;' : ''}
    }

    ul {
      padding-left: 3rem;
    }

    .pull-quote {
      padding: 64px 64px;

      .quote {
        font-weight: 600;

        font-size: 28px;
        line-height: 34px;
        letter-spacing: -0.71px;
      }
      .quote-attribution {
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.16px;
        margin: 15px 0 0;
      }
    }

    .feature-stat {
      margin: 90px 0;
      div {
        p.stat {
          font-size: 157.51px;
          line-height: 188px;
          letter-spacing: -11.73px;
          margin: 0;
          padding: 0;

          span {
            font-size: 100.02px;
            letter-spacing: -7.45px;
          }
        }
      }
      p.feature-stat-description {
        margin-top: 10px;
        font-size: 17px;
        line-height: 26px;
        letter-spacing: -0.12px;
      }
    }

    .feature-image {
      margin: 60px 0;
      p.feature-image-attribution {
        margin-top: 10px;
        font-size: 17px;
        line-height: 26px;
        letter-spacing: -0.12px;
      }
    }
  }

  @media (min-width: 1200px) {
    padding: 150px 187px 120px;
    max-width: 1440px;
    margin: 0 auto;

    p, ul, ol {
      font-size: 22px;
      letter-spacing: -0.41px;
      padding: 0 140px;
      ${props => props.fullwidth ? 'padding: 0;' : ''}
    }

    ul {
      padding-left: 3rem;
    }

    .feature-stat {
      margin: 120px 0;
      div {
        p.stat {
          font-size: 250px;
          line-height: 301px;
          letter-spacing: -18.62px;

          span {
            font-size: 158.76px;
            line-height: 191px;
            letter-spacing: -11.83px;
          }
        }
      }
    }

    .pull-quote {
      padding: 90px 64px;

      .quote {
        font-size: 38px;
        line-height: 48px;
        letter-spacing: -1.47px;
      }
      .quote-attribution {
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.16px;
        margin: 25px 0 0;
      }
    }

    .feature-image {
      margin: 120px 0;
    }
  }
`;

export default PostContent;