import React from "react";
import { graphql } from "gatsby";
import tw, { styled } from "twin.macro";
import Layout from "../components/Layout/Layout";
import SEO from "../components/seo";
import GFForm from "../components/PageComponents/Forms/GFForm";
import DisclaimerText from "../components/PageComponents/DisclaimerText";
import PostContent from "../components/PostContent";

const FormContent = styled.div`
  h2,
  h3 {
    ${tw`text-2xl sm:text-4xl leading-tight mb-2`}
  }

  p {
    ${tw`text-sm sm:text-lg leading-normal`}
  }

  .wp-block-separator {
    border-top-width: 2px;
    margin-bottom: 1rem;
    margin-top: 1rem;

    @media (min-width: 640px) {
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;
    }
  }

  strong {
    ${tw`text-magenta`}
  }
`;

const PetitionLayout = styled.div`
  ${tw`w-full bg-contain bg-no-repeat`}
  background-color: ${props => props.background_color ?? "#0F1E2D"};

  @media (min-width: 1024px) {
    ${tw`overflow-scroll bg-cover bg-center pt-0`}
    background-image: linear-gradient(to top, rgba(15, 30, 45, 0.7), transparent 40%), url(${props =>
      props.background});
    max-height: calc(100vh - 78px);
  }

  @media (min-width: 1280px) {
    max-height: calc(100vh - 90px);
  }

  .mobile-image {
    width: 100%;
    max-width: 30rem;
    margin: 0 auto;

    @media(min-width: 640px) {
      max-width: 42rem;
    }

    @media(min-width: 1024px) {
      display: none;
    }
  }

  form {
    ${tw`flex flex-wrap -mx-2 mt-6 lg:mt-8`}

    .first-name-field { ${tw`w-full sm:w-1/2`} }
    .last-name-field { ${tw`w-full sm:w-1/2`} }
    .email-field { ${tw`w-3/5 sm:w-3/4`} }
    .address-field { ${tw`w-2/5 sm:w-1/4`} }
    .zip-address-field { ${tw`w-full sm:w-full`} }
    .submit-button { ${tw`mt-6 bg-magenta`} }
    .submit-button[disabled] { background-color: #C6314B }
  }
`;

const PetitionContent = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${tw`text-center text-3xl md:text-5xl mb-6 md:mb-16`}
  }
`;

const Petition = ({ data }) => {
  const {
    title,
    content,
    featured_media,
    yoast_meta,
    acf
  } = data.wordpressPage;
  const image = featured_media?.source_url;
  const {
    form_copy,
    form_align,
    call_to_action,
    photo_attribution,
    background_color
  } = acf;

  const form = data.gfForm;
  const align_class = {
    left: "xl:pl-16",
    right: "justify-end xl:pr-16"
  };

  const {
    yoast_wpseo_metadesc: seo_desc,
    yoast_wpseo_facebook_title: seo_fb_title,
    yoast_wpseo_facebook_description: seo_fb_desc,
    yoast_wpseo_twitter_title: seo_tw_title,
    yoast_wpseo_twitter_description: seo_tw_desc
  } = yoast_meta;

  const seo_image =
    yoast_meta.yoast_wpseo_social_defaults?.og_frontpage_image?.source_url ||
    "";
  const seo_fb_image =
    yoast_meta.yoast_wpseo_facebook_image?.source_url || seo_image;
  const seo_tw_image =
    yoast_meta.yoast_wpseo_twitter_image?.source_url || seo_image;

  return (
    <Layout>
      <SEO
        title={title}
        description={seo_desc}
        image={seo_fb_image}
        metaTitleFacebook={seo_fb_title}
        metaImageFacebook={seo_fb_image}
        metaDescFacebook={seo_fb_desc}
        metaTitleTwitter={seo_tw_title}
        metaImageTwitter={seo_tw_image}
        metaDescTwitter={seo_tw_desc}
      />
      <PetitionLayout background={image} background_color={background_color}>
        <img
          className="mobile-image"
          src={image}
          alt={`${title} featured image`}
        />
        <div className="container h-full">
          <div
            className={`h-full flex flex-wrap items-center ${align_class[form_align]}`}
          >
            <div
              id="form-container"
              className="bg-white shadow max-w-md mx-auto p-6 -mt-8 mb-4 lg:my-4 sm:p-12 sm:max-w-xl lg:mx-0 lg:w-1/2  lg:my-16 xl:w-5/12"
            >
              <FormContent
                dangerouslySetInnerHTML={{ __html: form_copy }}
              ></FormContent>
              <GFForm id={form.formId} data={form} clearOnSuccess />
              <DisclaimerText>{photo_attribution}</DisclaimerText>
            </div>
          </div>
        </div>
      </PetitionLayout>
      {content && (
        <PetitionContent>
          <PostContent
            fullwidth
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </PetitionContent>
      )}
      {call_to_action && (
        <div className="bg-magenta py-12 lg:py-24 text-white text-center">
          <div className="container">
            <h3 className="lg:text-7xl">{call_to_action.header}</h3>
            <p className="lg:text-2xl">{call_to_action.copy}</p>
            <a
              className="uppercase text-white font-bold inline-block leading-none border-2 border-solid border-white py-4 px-10 mt-4 rounded-full lg:mt-6 lg:text-xl"
              href={call_to_action.link.url}
              target={call_to_action.link.target}
            >
              {call_to_action.link.title}
            </a>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Petition;

export const petitionQuery = graphql`
  query($id: String!, $form_title: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      featured_media {
        source_url
      }
      acf {
        form_copy
        form_align
        photo_attribution
        call_to_action {
          header
          copy
          link {
            target
            title
            url
          }
        }
        background_color
      }
      yoast_meta {
        yoast_wpseo_social_defaults {
          og_frontpage_image {
            source_url
          }
        }
        yoast_wpseo_metadesc
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_image {
          source_url
        }
        yoast_wpseo_facebook_description
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_image {
          source_url
        }
        yoast_wpseo_twitter_description
      }
    }
    gfForm(title: { eq: $form_title }) {
      formId
      title
      description
      slug
      apiURL
      confirmations {
        id
        name
        isDefault
        type
        message
        url
        queryString
      }
      formFields {
        type
        label
        placeholder
        cssClass
        id
        inputs {
          name
          label
          inputType
          choices {
            text
            value
          }
          isHidden
          id
          placeholder
        }
        isRequired
        addressType
        checkboxLabel
        choices
        conditionalLogic
        defaultValue
      }
    }
  }
`;
